<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import DataLoader from "@/components/data-loader.vue";
import TransactionTableCard from "@/components/dashboard/transaction/transaction-table-card.vue";
import API from "@/plugins/axios-method";
import Pagination from "@/components/dashboard/Pagination.vue";

export default {
  components: {Pagination, TransactionTableCard, DataLoader, PageHeader, Layout},
  data(){
    return {
      showDataLoader : true,
      transactions: [],
      startDate: '',
      endDate: '',
      next: null,
      previous: null,
      page: 1,
      totalItems: 0,
      url: ''
    }
  },
  mounted() {
    this.initDate()
    this.getTransactions()
  },
  methods: {
    initUrl(){
      this.url = '/transaction/failed?startDate=' + this.startDate + '&endDate=' + this.endDate + '&page=' + this.page
    },
    initDate(){
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()

      this.startDate = year + '-01-01'
      this.endDate = year + '-' + month + '-' + day

      this.initUrl()
    },
    setPagination(response){
      if (response.data.totalPages > 1) {
        this.totalItems = response.data.total
        if (response.data.page === response.data.totalPages){
          this.next = this.next = '/transaction/failed?startDate=' + this.startDate + '&endDate=' + this.endDate + '&page=' + this.totalPages
        } else {
          this.next = '/transaction/failed?startDate=' + this.startDate + '&endDate=' + this.endDate + '&page=' +  (this.page + 1)
        }
        if (response.data.page === 1){
          this.previous = '/transaction/failed?startDate=' + this.startDate + '&endDate=' + this.endDate + '&page=' + 1
        } else {
          this.previous = '/transaction/failed?startDate=' + this.startDate + '&endDate=' + this.endDate + '&page=' + (this.page - 1)
        }
      }
    },
    getTransactions(){
      API.get(this.url)
          .then(response => {
            if (response.status === 200){
              this.setPagination(response)
              response.transactions.map(t =>{
                this.transactions.push(t)
              })
              this.showDataLoader = false
            }
          })
          .catch(() => {
            this.showDataLoader = false
          })
    },
    nextPage(){
      this.setCurrentPage(this.next)
      this.transactions = []
      this.getTransactions()
    },
    previousPage(){
      this.setCurrentPage(this.previous)
      this.transactions = []
      this.getTransactions()
    },
    navigate(page){
      this.page = page
      this.transactions = []
      this.showDataLoader = true
      this.initUrl()
      this.getTransactions()
    },
    setCurrentPage(url){
      const currentPage = Number(url.split('page=')[1])
      if (currentPage !== this.page) this.page = currentPage
      this.url = url
      this.showDataLoader = true
    },
    handleFilter(date){
      this.showDataLoader = true
      this.startDate = date.startDate
      this.endDate = date.endDate
      this.initUrl()
      this.initNavigation()
      this.transactions = []
      this.getTransactions()
    },
    initNavigation(){
      this.next = null
      this.previous = null
    }
  }

}
</script>

<template>
  <Layout>
    <PageHeader
        :show-add-button="false"
        :title="'Transactions Echouées'"
        :show-search="false"
        :show-date-filter="true"
        @submitDateFilter="handleFilter"
    />
    <DataLoader v-if="showDataLoader"/>
    <section>
      <div class="card" v-if="transactions.length > 0 && !showDataLoader">
        <div class="card-body">
          <TransactionTableCard
              is-all-transactions
              :transactions="transactions"
          />
          <Pagination
              :next="next"
              :previous="previous"
              :currentPage="page"
              :totalItems="totalItems"
              @navigate="navigate"
              @nextPage="nextPage"
              @previousPage="previousPage"
          />
        </div>
      </div>
    </section>
  </Layout>
</template>

<style scoped>

</style>